import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
		path: "/",
		redirect: "/index"
	},
	{
		path: '/index',
		name: 'index',
		meta: {
			scrollToTop: true
		},
		component: () => import('../views/index/index.vue')
	},
	{
		path: '/login',
		name: 'login',
		meta: {
			scrollToTop: true
		},
		component: () => import('../views/login/index.vue')
	},
	{
		path: '/item/detail',
		name: 'itemDetail',
		meta: {
			scrollToTop: true
		},
		component: () => import('../views/item/detail.vue')
	}, {
		path: '/item/search',
		name: 'itemSearch',
		meta: {
			scrollToTop: true
		},
		component: () => import('../views/item/search.vue')
	}, {
		path: '/member/cart',
		name: 'memberCart',
		meta: {
			scrollToTop: true
		},
		component: () => import('../views/member/cart.vue')
	}, {
		path: '/order/confirm',
		name: 'orderConfirm',
		meta: {
			scrollToTop: true
		},
		component: () => import('../views/order/confirm.vue')
	}, {
		path: '/order/order',
		name: 'order',
		meta: {
			scrollToTop: true
		},
		component: () => import('../views/order/order.vue')
	}, {
		path: '/about',
		name: 'about',
		meta: {
			scrollToTop: true
		},
		component: () => import('../views/index/about.vue')
	}, {
		path: '/apply',
		name: 'apply',
		meta: {
			scrollToTop: true
		},
		component: () => import('../views/apply/apply.vue')
	}, {
		path: '/apply/list',
		name: 'applyList',
		meta: {
			scrollToTop: true
		},
		component: () => import('../views/apply/list.vue')
	}, {
		path: '/question',
		name: 'question',
		meta: {
			scrollToTop: true
		},
		component: () => import('../views/order/question.vue')
	}, {
		path: '/addr',
		name: 'addr',
		meta: {
			scrollToTop: true
		},
		component: () => import('../views/member/addr.vue')
	}, {
		path: '/service',
		name: 'service',
		meta: {
			scrollToTop: true
		},
		component: () => import('../views/archive/service.vue')
	}, {
		path: '/feedback',
		name: 'feedback',
		meta: {
			scrollToTop: true
		},
		component: () => import('../views/sys/feedback.vue')
	}, {
		path: '/receivingStandards',
		name: 'receivingStandards',
		meta: {
			scrollToTop: true
		},
		component: () => import('../views/archive/receivingStandards.vue')
	},  {
		path: '/userAgreement',
		name: 'userAgreement',
		meta: {
			scrollToTop: true
		},
		component: () => import('../views/archive/userAgreement.vue')
	},{
		path: '/apply/supplier',
		name: 'supplier',
		meta: {
			scrollToTop: true
		},
		component: () => import('../views/apply/supplier.vue')
	},{
		path: '/jump',
		name: 'supplier',
		meta: {
			scrollToTop: true
		},
		component: () => import('../views/applet/jump.vue')
	}
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

// 防止路由重复点击报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

export default router