import request from '@/utils/request'

export function cartAdd(params) {
	return request({
		url: '/cart/add',
		method: 'post',
		data: params
	})
}

export function cartNum(params) {
	return request({
		url: '/cart/num',
		method: 'post',
		data: params
	})
}

export function cartList(params) {
	return request({
		url: '/cart/list',
		method: 'post',
		data: params
	})
}

export function cartDel(params) {
	return request({
		url: '/cart/del',
		method: 'post',
		params
	})
}

export function cartBatchDel(params) {
	return request({
		url: '/cart/batch/del',
		method: 'post',
		data: params
	})
}

export function addrList(params) {
	return request({
		url: '/addr/list',
		method: 'post',
		data: params
	})
}

export function addrAdd(params) {
	return request({
		url: '/addr/add',
		method: 'post',
		data: params
	})
}

export function addrEdit(params) {
	return request({
		url: '/addr/edit',
		method: 'post',
		data: params
	})
}

export function addrDel(params) {
	return request({
		url: '/addr/del',
		method: 'post',
		params
	})
}