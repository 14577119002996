<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	import {
		setCookie
	} from '@/utils/auth'
	export default {
		data() {
			return {};
		},
		created() {

		},
	};
</script>

<style lang="scss">
	body {
		color: #333;
	}

	.link-color {
		color: #20557b;
		cursor: pointer;
		font-size: 14px;
	}

	.common-header {
		width: 100%;
		min-width: 1200px;
		border-bottom: 1px solid #ddd;

		.search-healder {
			max-width: 1200px;
			height: 120px;
			margin: 0 auto;
			display: flex;
			width: 100%;
			font-size: 13px;
			flex-wrap: wrap;

			.logo-box {
				width: 80px;
				height: 80px;
				margin-top: 20px;
				margin-left: 90px;
			}

			.logo {
				width: 80px;
				height: 80px;
				cursor: pointer;
			}

			input {
				width: 562px;
				height: 40px;
				border-radius: 20px;
				border-color: #D9001B;
				padding-left: 24px;
				font-size: 15px;
				margin-left: 30px;
				margin-top: 10px;
				outline: none;
			}

			.search {
				margin-top: 25px;
			}

			.search-btn {
				width: 80px;
				height: 40px;
				border-radius: 20px;
				background-color: #D9001B;
				color: #fff;
				text-align: center;
				cursor: pointer;
				transition: all 0.3s linear;
				line-height: 40px;
				margin-left: 15px;
				font-size: 20px;
			}
		}

		.menu {
			width: 100%;
			height: 60px;
			background-color: #f7f7f7;

			.menu-box {
				max-width: 1200px;
				display: flex;
				flex-wrap: wrap;
				margin: 0 auto;

				.menu-list {
					width: 120px;
					height: 60px;
					text-align: center;
					line-height: 60px;
					font-size: 16px;
					cursor: pointer;
				}

				.menu-list:hover {
					color: #D9001B;
					opacity: 0.7;
					transition: .3s;
				}

				.menu-active {
					background-color: #D9001B;
					color: #fff;
				}

				.menu-active:hover {
					background-color: #D9001B;
					color: #fff;
				}
			}
		}
	}

	input[type=number]::-webkit-outer-spin-button,
	input[type=number]::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	input[type=number] {
		-moz-appearance: textfield;
	}

	.el-message-box {
		.el-button--primary {
			border: none;
			background-color: #D9001B;
		}

		.el-button--primary:hover {
			background-color: #D9001B;
			opacity: 0.7;
		}
	}

	.h30 .el-input__inner {
		height: 30px;
	}

	.bh30 {
		height: 30px;
		line-height: 0px !important;
	}
</style>