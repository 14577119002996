import axios from 'axios'
import {
	MessageBox,
	Message
} from 'element-ui'
import store from '@/store'
import router from '@/router'
import {
	getToken,
	delCookie
} from '@/utils/auth'

// var apiUrl = 'http://localhost:8086/v3'

var apiUrl='https://aksojk.com/xlymall/v3'

// create an axios instance
const service = axios.create({
	baseURL: apiUrl,
	timeout: -1
})

// request interceptor
service.interceptors.request.use(
	config => {
		if (getToken()) {
			config.headers['Authorization'] = getToken()
		}
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	response => {
		const res = response.data
		if (res.code == 401) {
			delCookie('token')
			delCookie('userInfo')
			router.push({
				path: '/login'
			})
		} else if (res.code == 10003) {
			delCookie('token')
			delCookie('userInfo')
			router.push({
				path: '/login'
			})
		}
		return res
	},
	error => {
		return Promise.reject(error)
	}
)

export default service


export function getUrl() {
  return apiUrl
}