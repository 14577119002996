<template>
	<div class="footer">
		<div class="footer-box">
			<el-row :gutter="24">
				<el-col :span="16">
					<el-col :span="6">
						<div class="title">新手上路</div>
						<div class="item-list" @click="toUrl('/login')">会员注册</div>
						<div class="item-list" @click="toUrl('/login')">会员登陆</div>
						<div class="item-list" @click="toUrl('/feedback')">投诉反馈</div>
					</el-col>
					<el-col :span="6">
						<div class="title">商务合作</div>
						<div class="item-list" @click="toUrl('/apply/supplier')">供应商合作</div>
						<div class="item-list" @click="toUrl('/apply/list')">企业入驻</div>
						<div class="item-list" @click="jump('http://wwmall.vip/shop/')">商家后台</div>
					</el-col>
					<el-col :span="6">
						<div class="title">关于我们</div>
						<div class="item-list" @click="toUrl('/about')">公司咨询</div>
						<div class="item-list" @click="service(0)">隐私政策</div>
						<div class="item-list" @click="service(1)">用户协议</div>
						<div class="item-list" @click="service(2)">药品收货标准</div>
					</el-col>
					<el-col :span="6">
						<div class="title">友情链接</div>
						<div class="item-list" @click="jump('http://www.kuaidi.com/')">快递查询</div>
						<div class="item-list"
							@click="jump('https://yaojianju.cq.gov.cn/zwgk_217/fdzdgknr/jdjc/jggzbz/202301/t20230112_11492411.html')">
							药品网络销售监督管理办法</div>
						<div class="item-list"
							@click="jump('https://www.gov.cn/zhengce/2022-09/02/content_5708057.htm')">药品网络销售监督管理办法政策解读
						</div>
					</el-col>
				</el-col>
				<el-col :span="4">
					<div class="title">微信扫码进入小程序</div>
					<div class="line">阿克索健康</div>
					<div class="code">
						<img src="../image/code/code.jpg" />
					</div>
				</el-col>
				<el-col :span="4">
					<div class="title">微信扫码关注公众号</div>
					<div class="line">阿克索健康</div>
					<div class="code">
						<img src="https://aksogw.oss-cn-hangzhou.aliyuncs.com/index/ewm.jpg" />
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="service">
			<div class="top-header">
				<ul class="top-row">
					<li class="col">
						<a class="link-tag">
							<img src="../image/index/phone.png" alt="">
							<span>全国服务热线:4006316518</span>
						</a>

					</li>
					<li class="col">
						<a class="link-tag">
							<img src="../image/index/phone.png" alt="">
							<span>郑先生:17774941210</span>
						</a>
					</li>

					<li class="col">
						<a class="link-tag">
							<img src="../image/index/phone.png" alt="">
							<span>任女士:18315227074</span>
						</a>
					</li>
					<li class="col">
						<a class="link-tag">
							<img src="../image/index/phone.png" alt="">
							<span>张女士:15023258528</span>
						</a>
					</li>
					<li class="col">
						<a class="link-tag">
							<img src="../image/index/email.png" alt="">
							<a class="email" href="mailto:2313498079@qq.com">2313498079@qq.com</a>
						</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="f-bottom">COPYRIGHT © 2023 重庆阿克索实业有限公司
			<a target="_blank" href="http://www.miit.gov.cn/">
				渝ICP备18009072号-2
			</a>
			<a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch">
				渝公网安备50010302005048号
			</a>
		</div>
	</div>
</template>

<script>
	import Cookies from 'js-cookie'
	import {
		setCookie,
		getCookie,
		delCookie
	} from '@/utils/auth'
	export default {
		data() {
			return {};
		},
		created() {},
		methods: {
			toUrl(path) {
				if (path == '/login') {
					setCookie("loginType", '会员')
				} else if (path == '/apply/list') {
					setCookie("loginType", '企业')
				}

				this.$router.push({
					path: path
				})
			},
			service(type) {
				if (type == 0) { //隐私政策
					var routeData = this.$router.resolve({
						path: '/service'
					})
				} else if (type == 1) { //用户协议
					var routeData = this.$router.resolve({
						path: '/userAgreement'
					})
				} else if (type == 2) { //药品收货标准
					var routeData = this.$router.resolve({
						path: '/receivingStandards'
					})
				}
				window.open(routeData.href, "_blank");
			},
			jump(url) {
				window.open(url)
			},
		},
	};
</script>

<style lang="scss">
	.footer {
		width: 100%;
		border-top: 1px solid #ddd;
		margin-top: 20px;
		min-width: 1200px;

		.footer-box {
			max-width: 1200px;
			margin: 0 auto;
			min-height: 130px;
			margin-bottom: 10px;

			.el-row {
				margin-top: 10px;
				text-align: center;

				.title {
					color: #333;
					font-size: 16px;
				}

				.item-list {
					line-height: 30px;
					font-size: 14px;
					cursor: pointer;
				}

				.item-list:hover {
					color: #20557b;
					text-decoration: underline;
				}

				.line {
					width: 120px;
					height: 30px;
					line-height: 30px;
					background-color: #D9001B;
					border-top-left-radius: 20px;
					border-top-right-radius: 20px;
					color: #fff;
					margin: 0 auto;
					margin-top: 5px;
				}

				.code {
					width: 120px;
					height: 120px;
					border: 1px solid #ddd;
					border-bottom-left-radius: 20px;
					border-bottom-right-radius: 20px;
					margin: 0 auto;

					img {
						width: 100px;
						height: 100px;
					}
				}
			}
		}

		.service {
			height: 80px;
			width: 100%;
			line-height: 80px;
			background-color: #7d8aff;
			color: #fff;

			.top-header {
				max-width: 1200px;
				margin: 0 auto;
				display: flex;
				flex-wrap: wrap;
				flex-flow: column;
				width: 100%;
				position: relative;
				font-size: 14px;

				.top-row {
					display: flex;
					flex-wrap: wrap;
					align-items: center;

					.col {
						position: relative;

						.link-tag {
							color: #fff;
							cursor: pointer;
							display: flex;
							margin-left: 45px;

							img {
								width: 20px;
								height: 20px;
								margin-top: 30px;
							}

							span {
								padding-left: 5px;
							}

							.email {
								padding-left: 5px;
								text-decoration: none;
								color: #fff !important;
							}
						}
					}

				}
			}
		}

		.f-bottom {
			height: 60px;
			line-height: 60px;
			background-color: #D9001B;
			color: #fff;
			width: 100%;
			text-align: center;
			font-size: 14px;

			a {
				padding-left: 10px;
				color: #fff;
			}

			a:hover {
				text-decoration: underline;
			}
		}
	}
</style>