<template>
	<div class="header">
		<div class="top-header">
			<ul class="top-row">
				<li class="col navbar-left" style="font-weight: bold;">
					祝天下人健康,全球一站式健康管家
				</li>
				<li class="col navbar-right">
					<ul class="top-row">
						<li class="col">
							<span v-if="!user">Hi, 欢迎来到阿克索健康国际 </span>
							<span v-if="user">Hi,
								<span class="link-color">{{getPhone(user.phone)}}</span>
							</span>
							<a class="link-color" @click="toUrl('/login')" v-if="!user">登陆 /</a>
							<a class="link-color" @click="logout()" v-if="user"> / 退出</a>
							<a class="link-color" @click="toUrl('/login')" v-if="!user">注册</a>
						</li>
						<li class="col">
							<a class="link-tag" @click="toUrl('/member/cart')" v-if="user">
								<img src="../image/index/car.png" alt="">
								<span>购物车 <span class="car-num" v-if="carNum>0">{{carNum}}</span></span>
							</a>
						</li>
						<li class="col">
							<a class="link-tag" @click="toUrl('/order/order')" v-if="user">
								<span>我的订单</span>
							</a>
						</li>
						<li class="col">
							<a class="link-tag" @click="toUrl('/apply/list')" v-if="user">
								<span>入驻申请</span>
							</a>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import Cookies from 'js-cookie'
	import {
		setCookie,
		getCookie,
		delCookie
	} from '@/utils/auth'
	import {
		cartNum
	} from '@/api/member/member'
	export default {
		data() {
			return {
				user: null,
				carNum: 0
			};
		},
		created() {
			var that = this
			var login = setInterval(function() {
				var loginForm = Cookies.get('userInfo')
				if (loginForm) {
					that.user = JSON.parse(loginForm)
					that.getCartNum()
					clearInterval(login)
				}
			}, 300)
		},
		methods: {
			toUrl(path) {
				this.$router.push({
					path: path
				})
			},
			getPhone(phone) {
				return phone.substring(0, 3) + '****' + phone.substring(phone.length - 4)
			},
			logout() {
				Cookies.remove('token')
				Cookies.remove('userInfo')
				location.reload()
			},
			getCartNum() {
				var that = this
				cartNum({}).then(res => {
					that.carNum = res.data
					setCookie("carNum", res.data)
					setInterval(function() {
						if (getCookie("carNum")) {
							that.carNum = parseInt(getCookie("carNum"))
						}
					}, 1000)
				})
			}
		},
	};
</script>

<style lang="scss">
	.header {
		width: 100%;
		border-bottom: 1px solid #ddd;

		.top-header {
			max-width: 1200px;
			min-width: 1200px;
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			flex-flow: column;
			width: 100%;
			position: relative;
			font-size: 13px;

			.top-row {
				display: flex;
				flex-wrap: wrap;
				align-items: center;

				.col {
					padding: 8px;
					position: relative;

					.text-highlight {
						color: #D9001B;
						padding-left: 10px;
					}

					.link-tag {
						color: #000;
						cursor: pointer;
						display: flex;

						img {
							width: 20px;
							height: 20px;
						}

						span {
							padding-left: 5px;

							.car-num {
								position: absolute;
								width: 15px;
								height: 15px;
								background: #D9001B;
								line-height: 15px;
								text-align: center;
								border-radius: 15px;
								padding: 0;
								font-size: 10px;
								color: #fff;
								margin-top: -5px;
							}
						}
					}
				}

				.navbar-left {
					width: 65%;
				}

				.navbar-right {
					flex-grow: 1;
					padding: 0;
				}
			}
		}
	}
</style>