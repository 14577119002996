import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import '@/styles.scss'
import '@/style/main.scss'
import * as filters from './filters'
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import {
	Loading
} from 'element-ui'
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999,
  }
})

Vue.use(ElementUI)
Vue.component('Footer', Footer);

Vue.component('Header', Header);
// 图片上传组件
import ImageUpload from '@/components/ImageUpload'

Vue.component('ImageUpload', ImageUpload)

// 页面跳转后滚动条位于最上方
router.afterEach((to, from, next) => {
	window.scrollTo(0, 0);
});

// register global utility filters
Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key])
})

window.SeoUpdate = function(SeoTitle, SeoKeywords, SeoDescription) {
	let _headDom = '',
		_title = '',
		_meta = '';

	_headDom = document.getElementsByTagName('head')[0]; //获取head节点
	_title = _headDom.getElementsByTagName("title")[0]; //获取head节点下的title节点
	_meta = _headDom.getElementsByTagName("meta"); //获取head节点下的meta节点，它一般是一个数组
	_title.innerText = SeoTitle;
	for (let index = 0; index < _meta.length; index++) {
		switch (_meta[index].name) {
			case 'keywords':
				_meta[index].content = SeoKeywords;
				break;
			case 'description':
				_meta[index].content = SeoDescription;
				break;
			default:
				break;
		}
	}
}


Vue.prototype.Loading = Loading
Vue.config.productionTip = false
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
